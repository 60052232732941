<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>用户信息</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片 -->
    <el-card>
      <!-- 个人信息 表单 -->
      <el-form :model="ownForm" label-width="80px" style="width:400px" disabled>
        <el-form-item label="手机号">
          <el-input v-model="ownForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="岗位">
          <el-input v-model="ownForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="公司" v-if="ownForm.roleName!='超级管理员'">
          <el-input v-model="ownForm.companyName"></el-input>
        </el-form-item>
        <el-form-item label="项目" v-if="disableItem.indexOf(ownForm.roleName)==-1">
          <el-input v-model="ownForm.itemNames"></el-input>
        </el-form-item>
              
      </el-form>
      <el-button style="margin-left:80px" type="primary" @click="updateDialogVisible=true">修改密码</el-button>

    <el-dialog v-model="updateDialogVisible" title="修改密码" width="50%" @closed="closedForm()">
      <el-form :model="updateForm" :rules="rules" ref="updateForm" label-width="100px">
        <el-form-item label="旧密码" prop="oldPass">
          <el-input v-model="updateForm.oldPass" type="password" autocomplete="off" show-password ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input v-model="updateForm.newPass" type="password" autocomplete="off" show-password ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="updateDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="updatePassword('updateForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      disableItem:['超级管理员','公司管理员','公司负责人'],
      // 如果用户多公司多项目，返回的则是当前公司当前项目
      ownForm: {
        phone:"",
        roleName:"",
        companyName:"",
        itemNames:""
      },
      updateForm: {
        oldPass:"",
        newPass:""
      },
      updateDialogVisible: false,
      rules: {
        oldPass: [{ validator: validatePass, trigger: 'blur' }],
        newPass: [{ validator: validatePass, trigger: 'blur' }]
      }
    };
  },
  mounted(){
    this.getOwnForm();
  },
  methods:{
    async getOwnForm(){
      const { data: res } = await this.$http.get("user/getOwner");
      if (res.code != 200) {
        return this.$message.error(res.msg);
      }
      this.ownForm = res.data;
      if(res.data.itemList == null){
        this.ownForm.itemNames = "无"
      }else{
        this.ownForm.itemNames = this.getItemNames();
      }
    },
    closedForm(){
      this.$refs.updateForm.resetFields()
    },
    updatePassword(form) {
      this.$refs[form].validate(async valid => {
        if (!valid) { 
          return;
        }
        let formData = new FormData();
        formData.append('oldPass',this.$md5(this.updateForm.oldPass))
        formData.append('newPass',this.$md5(this.updateForm.newPass))
        const { data: res } = await this.$http.post("user/updatePassword", formData);
        if (res.code !== 200) return this.$message.error(res.msg);
        this.$message.success(res.msg);
        this.updateDialogVisible=false;
      });
    },
    getItemNames(){
      var res=""
      for(var i of this.ownForm.itemList){res = res + i;}
      if(res == ""){res = "无"}
      return res;
    },
  }
};
</script>